<template>
    <div class="brand full-width">
        <div class="business__main-image">
            <div class="business__main-image__opacity"></div>
            <div class="business__main-image__container">
                <div class="business__main-image__container__text">
                    <div class="business__main-image__container__title">Укрепите свой бренд</div>
                    <BaseButton
                        class="button-fill business__main-image__container__button"
                        @click="goTo('blogger-registration')"
                    >Начать</BaseButton>
                </div>
            </div>
        </div>
        <div class="business__form-block">
            <div class="business__form-block__title">Повысьте лояльность к бренду с помощью путешествий</div>
            <div v-if="isDesktop" class="business__form-block__container">
                <div class="business__form-block__picture">
                    <div class="business__form-block__text-block">
                        <div class="business__form-block__text-block__title">Готовы вывести свой бизнес на новый уровень?</div>
                        <div class="business__form-block__text-block__text">GetAdventures поможет вам отправиться в путешествие, которое запомнится. Мы стремимся сделать путешествия более безопасными, легкими и доступными для всех. Это означает сделать его более приятным для всех, улучшая жизнь вашего сообщества за счет значимых связей, обучения и исследований.</div>
                        <!-- <div class="business__form-block__text-block__description">Вдохновите свое сообщество путешествовать вместе с вами по удивительным местам по всему миру.</div> -->
                    </div>
                </div>
                <FormRegistration />
            </div>
            <div v-else>
                <div class="business__form-block__picture_mobile"></div>
                <div class="business__form-block__text-block_mobile">
                    <div class="business__form-block__text-block__title">Готовы вывести свой бизнес на новый уровень?</div>
                    <div class="business__form-block__text-block__text">GetAdventures поможет вам отправиться в путешествие, которое запомнится. Мы стремимся сделать путешествия более безопасными, легкими и доступными для всех. Это означает сделать его более приятным для всех, улучшая жизнь вашего сообщества за счет значимых связей, обучения и исследований.</div>
                    <div class="business__form-block__text-block__description">Вдохновите свое сообщество путешествовать вместе с вами по удивительным местам по всему миру.</div>
                </div>
                <FormRegistration />
            </div>
        </div>
        <div class="business__pink-block">
            <div class="business__pink-block__icon"></div>
            <div class="business__pink-block__text">Как работает GetAdventures для бизнеса</div>
        </div>
        <div class="business__main-container">
            <div class="business__block">
                <div class="business__block__text-block">
                    <div class="business__block__text-block__text">
                            <div class="business__block__text-block__title">Дифференцируйте свой бизнес</div>
                        <div class="business__block__text-block__text__text">
                            GetAdventures для бизнеса предлагает платформу групповых путешествий, специально разработанную для брендов, которые хотят повысить лояльность и установить более глубокие связи с членами своего сообщества.
                            Это для компаний, которые хотят повысить уровень опыта, который они предоставляют своим членам, и поставить свой бренд на голову выше остальных.
                            Платформа GetAdventures — это весело, легко, и здесь вы буквально перенесете свой бренд в удивительное место.
                        </div>
                    </div>
                </div>
                <div class="business__block__picture business__block__picture__talk"></div>
            </div>
            <div class="business__block">
                <div class="business__block__text-block">
                    <div class="business__block__text-block__text">
                            <div class="business__block__text-block__title">Оставьте планирование нам</div>
                        <div class="business__block__text-block__text__text">
                            Мы создали эту новую платформу, чтобы предоставить вашему сообществу невероятные впечатления от путешествий.
                            Мы позаботимся обо всем, от транспортировки внутри страны до антикризисного управления, чтобы вы могли легко масштабировать новый поток доходов и экспериментальный маркетинговый канал.
                            Все это дополняется совместным, практическим опытом путешествий, который показывает членам вашего сообщества, что вы предлагаете и кто вы есть.
                        </div>
                    </div>
                </div>
                <div class="business__block__picture business__block__picture__plan"></div>
            </div>
            <div class="business__block">
                <div class="business__block__text-block">
                    <div class="business__block__text-block__text">
                            <div class="business__block__text-block__title">Дайте людям новый способ взаимодействия с вашим брендом</div>
                        <div class="business__block__text-block__text__text">
                            Сочетая устойчивые групповые путешествия с автоматизацией передачи данных, платформа GetAdventures для бизнеса предлагает людям новый и увлекательный способ взаимодействия с вашим брендом в реальном мире.
                            Это групповые и деловые поездки в лучшем виде.
                        </div>
                    </div>
                </div>
                <div class="business__block__picture business__block__picture__brend"></div>
            </div>
        </div>
        <div class="business__pink-block" style="margin-bottom: 0">
            <div class="business__pink-block__container">
                <div class="business__pink-block__text">Посмотреть все наши направления</div>
                <BaseButton
                    class="button-fill"
                    style="border: 1px solid #FFFFFF !important;"
                    @click="goTo('trip-list')"
                >Узнать больше</BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile, imageSrc, SplideSlideCounter } from '@/helpers';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { TRIP_STATUS, TRIP_TYPE } from '@/vars';

    import BaseButton from '../components/common/BaseButton.vue';
    import FormRegistration from '../components/common/FormRegistration.vue'
    import MobilePreview from '../components/common/trip/PreviewCard'

    import moment from '@/plugins/moment';
    moment.locale('ru');

    export default {
        name: 'Experts',
        components: {
            BaseButton,
            FormRegistration,
            Splide,
            SplideSlide,
            MobilePreview
        },
        data: () => ({
            timerDate: '',
            timer: null,
            isDesktop: false,
            imageSrc,
            tourSplideCounter: 1,
            header: 0,
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('trips', {
                trips: state => state.entities
            }),
        },
        created() {
            this.getTrips();
            this.isDesktop = !isMobile();
            this.tourSplideCounter = SplideSlideCounter(350, 1280);
            this.placesSplideCounter = SplideSlideCounter(260, 910, (0.3*innerWidth));
        },
        methods: {
            toggleDialogUserType(e) {
                this.$store.commit('setDialogUserType', !this.showDialogUserType);
            },
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
            async goTo(name) {
                await this.$router.push({ name });
            },
            async back() {
                await this.$router.back();
            },
            async getTrips() {
                await store.dispatch('trips/fetch', { filter: {
                    ...this.filter,
                    type: TRIP_TYPE.TOUR_LEADER,
                    status: TRIP_STATUS.ACTIVE
                }, sorting: this.sorting });
            },
            async favoriteAdd({ id }) {
                if (this.user._id) {
                    await store.dispatch('trips/favoriteAdd', { id });
                    await store.dispatch('auth/fetch');
                    await this.getTrips();
                } else {
                    this.authorization()
                }
            },
            async favoriteDelete({ id }) {
                if (this.user._id) {
                    await store.dispatch('trips/favoriteDelete', { id });
                    await store.dispatch('auth/fetch');
                    await this.getTrips();
                } else {
                    this.authorization()
                }   
            },
            async setStatus({ id, status }) {
                await store.dispatch('trips/setStatus', { id, status });
                await this.getTrips();
            },
        },
    };
</script>

<style lang="scss">
.business {
    width: 100%;
    &__main-image {
        position: relative;
        width: 100%;
        height: 440px;
        background: url(../assets/business/Header.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        overflow: hidden;
        @media all and (max-width: 768px) {
            height: 356px;
            background-position: center;
        }
        &__opacity {
            position: absolute;
            width: 100%;
            height: 100%;
            background: #000000;
            opacity: 0.5;
        }
        &__container {
        position: absolute;
        width: 100%;
        height: 440px;
        display: flex;
        flex-direction: column;
        // align-items: center;
        // justify-content: center;
        color: #FFFFFF;
            @media all and (max-width: 768px) {
                height: 254px;
            }
            &__text {
                display: flex;
                flex-direction: column;
                width: 1280px;
                margin: auto;
                @media all and (max-width: 768px) {
                    width: 100%;
                    padding: 20px;
                    margin: 0 auto;
                }
            }
            &__title {
                font-weight: bold;
                font-size: 56px;
                // margin-top: 245px;
                line-height: 150%;
                letter-spacing: -0.02em;
                text-align: center;
                margin-bottom: 20px;
                @media all and (max-width: 768px) {
                    font-weight: 800;
                    font-size: 24px;
                    margin-top: 115px;
                    margin-bottom: 20px;
                }
            }
            &__comment {
                font-size: 24px;
                margin-top: 8px;
                line-height: 150%;
                letter-spacing: -0.02em;
                text-align: center;
                @media all and (max-width: 768px) {
                    font-size: 20px;
                }
            }
            &__button {
                width: 180px;
                @media all and (max-width: 768px) {
                    width: 240px;
                }
            }
        }
        &__action {
            position: absolute;
            display: flex;
            height: 32px;
            width: 32px;
            top: 40px;
            background: #FFF;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                cursor: pointer;
            }
            &_back {
                left: 20px;
            }
            &__icon {
                width: 16px;
                height: 16px;
                margin-right: 4px;
            }
        }
    }
    &__form-block {
        max-width: 1360px;
        margin: 80px auto;
        @media all and (max-width: 768px) {
            margin: 60px 0;
            padding: 0 20px;
        }
        &__title {
            max-width: 1280px;
            margin: 0 auto 32px;
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            color: #273155;
            margin-bottom: 32px;
            @media all and (max-width: 768px) {
                margin: 0 0 24px;
            }
        }
        &__container {
            display: flex;
            justify-content: flex-end;
            @media all and (max-width: 768px) {
                flex-direction: column;
                justify-content: flex-start;
                padding: 0 20px;
            }
        }
        &__picture {
            height: 540px;
            width: 850px;
            background: url(../assets/brand/Car.jpg);
            background-position-x: 50px;
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: 80px;
            position: relative;
            &_mobile {
                width: 100%;
                height: 222px;
                margin-bottom: 20px;
                background: url(../assets/brand/Car.jpg);
                background-size: cover;
                border-radius: 12px;
            }
        }
        &__text-block {
            position: absolute;
            left: -70px;
            top: 32px;
            width: 400px;
            background: #FFFFFF;
            box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.05);
            border-radius: 12px;
            padding: 20px;
            &_mobile {
                margin-bottom: 20px;
                padding: 0 20px;
            }
            &__title {
                font-weight: 600;
                font-size: 28px;
                line-height: 140%;
                color: #273155;
                margin-bottom: 20px;
            }
            &__text {
                font-weight: 400;
                font-size: 18px;
                line-height: 160%;
                color: #616D89;
                margin-bottom: 20px;
            }
            &__description {
                font-weight: 600;
                font-size: 18px;
                line-height: 140%;
                color: #3A4256;
            }
        }
    }
    &__pink-block {
        width: 100%;
        height: 300px;
        background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 80px;
        @media all and (max-width: 768px) {
            height: 225px;
            margin-bottom: 60px;
        }
        &__container {
            width: 1400px;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @media all and (max-width: 768px) {
                width: 100%;
                flex-direction: column;
            }
            .business__pink-block__text {
                font-size: 46px;
                line-height: 56px;
                @media all and (max-width: 768px) {
                    font-size: 24px;
                    line-height: 130%;
                    margin-bottom: 20px;
                }
            }
        }
        &__icon {
            width: 55px;
            height: 55px;
            background: url(../assets/brand/icon.png);
            @media all and (max-width: 768px) {
                margin-bottom: 20px;
            }
        }
        &__text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 52px;
            line-height: 63px;
            color: #FFFFFF;
            @media all and (max-width: 768px) {
                font-size: 24px;
                line-height: 130%;
                text-align: center;
            }
        }
    }
    &__main-container {
        max-width: 1280px;
        margin: 0 auto;
    }
    &__block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 80px;
        &:nth-of-type(2n) {
            flex-direction: row-reverse;
        }
        @media all and (max-width: 768px) {
            flex-direction: column;
            margin-bottom: 50px;
            &:nth-of-type(2n) {
                flex-direction: column;
            }
        }
        &__text-block {
            display: flex;
            flex-direction: row;
            width: 500px;
            @media all and (max-width: 768px) {
                width: 100%;
                padding: 0 20px;
                margin-bottom: 12px;
            }
            &__text {
                display: flex;
                flex-direction: column;
                font-family: 'Inter';
                font-style: normal;
                &__text {
                    font-size: 18px;
                    line-height: 180%;
                    @media all and (max-width: 768px) {
                        font-size: 16px;
                        line-height: 140%;
                        margin-bottom: 28px;
                    }
                }
            }
            &__title {
                font-weight: 700;
                font-size: 38px;
                line-height: 46px;
                color: #273155;
                margin-bottom: 32px;
                @media all and (max-width: 768px) {
                    font-size: 28px;
                    line-height: 130%;
                }
            }
            &__button {
                width: 239px;
                height: 52px;
                margin: 0 auto 0 0;
                @media all and (max-width: 768px) {
                    width: 100%;
                    margin-bottom: 40px;
                }
            }
        }
        &__picture {
            width: 600px;
            height: 430px;
            background-size: cover;
            border-radius: 10px;
            @media all and (max-width: 768px) {
                width: calc(100% - 40px);
                height: 240px; 
            }
            &__talk {
                background-image: url(../assets/business/Differentiation.jpg);
            }
            &__plan {
                background-image: url(../assets/business/Flow.jpg);
            }
            &__brend {
                background-image: url(../assets/business/Interactions.jpg);
            }
        }
    }
    &__offers {
        max-width: 1280px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media all and (max-width:768px) {
            flex-direction: column;
        }
        &__offer {
            display: flex;
            flex-direction: row;
            padding: 32px;
            background: #F6F7F9;
            border-radius: 12px;
            @media all and (max-width:768px) {
                flex-direction: column;
                margin-bottom: 20px;
            }
            &__text {
                font-family: 'Inter';
                font-style: normal;
                margin-right: 50px;
                color: #273155;
            }
            &__title {
                font-weight: 600;
                font-size: 18px;
                line-height: 140%;
                @media all and (max-width:768px) {
                    font-size: 16px;
                    margin-bottom: 14px;
                }
            }
            &__comment {
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                @media all and (max-width:768px) {
                    font-size: 14px;
                    margin-bottom: 17px;
                }
            }
            &__button {
                @media all and (max-width:768px) {
                    width: 100%;
                }
            }
        }
    }
    &__block-name {
        width: 1280px;
        margin: 80px auto 40px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 38px;
        line-height: 46px;
        color: #273155;

    }
    &__similar-tours {
        display: flex;
        flex-direction: row;
        max-width: 1280px;
        margin: 30px auto 0;
        margin-right: -20px;
        @media all and (max-width: 768px) {
            flex-direction: column;
            margin-right: 0;
        }
    }
    &__similar-tours_splide {
        max-width: 1280px;
        margin: 30px auto 0;
        @media all and (max-width: 1400px) {
            width: calc(100% - 40px);
        } 
        .splide__slide {
            align-items: center;
        }
    }
    .v-btn > .v-btn__content .v-icon > .mdi-chevron-left {
        left: 100px;
    }
}

</style>